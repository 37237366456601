import './Mostre.css'
import home_img from "../../../images/arsnow.jpg";
import header_photo4 from "../../../images/background_header4.jpg";
import HeaderEn from "../../English/Header/Header";
import exhibitions from "../../../images/en/exhibitions.gif";
import Footer from "../Footer/Footer";

function Mostre(){
    return(
        <div className='mostre'>
            <a className='img_href' href='/'>
                <img src={home_img}/>
            </a>

            <div className='content'>
                <HeaderEn img={header_photo4}/>
                <img src={exhibitions}/>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Ars Now Seragiotto alla Biennale di
                    Venezia con il progetto "Alberto Biasi, Sara Campesan, Bruno Munari e altri amici di
                    Verifica 8+1"<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Arsnow4Ethyopia
                    - Outlet - 9a edizione<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Mostra "A dialogo" - Sara
                    Campesan &amp; Nadia Costantini<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Mostra fotografica
                    #bricioledivita di Giovanni de Sandre<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>ARSNOW
                    4 ETHIOPYA - Alessandro Borgonovo<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>VENEZIA, non solo
                    parole...<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>
                    ArsNow4Ethiopia - Paolo Ceribelli
                    - "TUTTO TORNA"<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>VivaMenteLido<span
                    className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>ARS
                    NOW 4 ETHIOPYA: Padova città "liquida"<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Frammenti del Lido
                    <span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>PoeticaMenteColli
                    <span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>ARSNOW IN NOTARUNION
                    <span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>ARS NOW 4
                    ETHIOPYA: FLOWERS<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>PERCORSI
                    (mostra collettiva)<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Francesco
                    Candeloro - Arthur Duff / Somewhere Parallel - Alterate Visioni<span
                        className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Flash Show 3: Vitali -
                    Vladkovska<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Nicola
                    Carrino Nelio Sonego<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Photo Show n.1 Camporesi,
                    Candeloro, Catastini, De Sandre, Frapiccini, Sambini<span className="style3"></span>
                </p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Flash Show 2
                    " I colori delle donne" Laura Stefani - Marco Querin<span className="style3"></span>
                </p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Andrew
                    Schoultz Ben Grasso Simmons &amp; Burke<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Riccardo De
                    Marchi , Bruno Querci<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Flash Show 1 : Paolo De Cuarto
                    "Carosello Retrò"<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Ulrich Rückreim<span
                    className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Günter Umberg, Elisabeth Vary<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Igino Legnaghi, Rolando
                    Tessadri<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Mario Nigro, Pino Pinelli<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Dadamaino Candeloro<span
                    className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Rodolfo Aricò, Carlo Ciussi<span className="style3"></span></p>
                <p className='mostre_paragraph'><span className="style3">&gt; </span>Enrico Castellani, François
                    Morellet<span className="style3"></span></p>
                <Footer/>
            </div>
        </div>
    )
}

export default Mostre;