import './Links.css'
import home_img from "../../../images/arsnow.jpg";
import Header from "../Header/Header";
import header_photo3 from "../../../images/background_header3.jpg";
import links from '../../../images/it/links.gif'
import Footer from "../Footer/Footer";

function Links() {
    return (
        <div className='links'>
            <a className='img_href' href='/'>
                <img src={home_img}/>
            </a>

            <div className='content'>
                <Header img={header_photo3}/>
                <img src={links}/>
                <p className='links_paragraph'>&gt;&nbsp;
                    <a className='facebook_link' href="http://www.labiennale.org/it/Home.html">
                        Biennale di Venezia
                    </a>
                </p>
                <p className='links_paragraph'>&gt;&nbsp;
                    <a className='facebook_link'
                       href="http://www.bevilacqualamasa.it/flex/cm/pages/ServeBLOB.php/L/IT/IDPagina/1">
                        Fondazione Bevilacqua La Masa
                    </a>
                </p>
                <p className='links_paragraph'>
                    <span className='links_span'>
                        <a className='links_span' href="http://www.aarteinvernizzi.it/" target="_blank">
                            <span className="style3">&gt;</span>&nbsp;
                        </a>
                        <a className='links_span' href="http://www.aarteinvernizzi.it/" target="_blank">A Arte Studio Invernizzi</a>
                    </span>
                </p>
                <p className='links_paragraph'>
                    <span className='links_span'>
                        <a className='links_span' href="https://www.lenostube.com/" target="_blank">
                            <span className="style3">&gt;</span>&nbsp;
                        </a>
                        <a className='links_span' href="https://www.lenostube.com/" target="_blank">LenosTube (partner video)</a>
                    </span>
                </p>

                <Footer/>
            </div>
        </div>
    )
}

export default Links;