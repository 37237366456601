import './Contatti.css'
import home_img from "../../../images/arsnow.jpg";
import Header from "../Header/Header";
import header_photo2 from "../../../images/background_header2.jpg";
import contatti from "../../../images/it/contatti.gif";
import Footer from "../Footer/Footer";

function Contatti(){
    return(
        <div className='contatti'>
            <a className='img_href' href='/'>
                <img src={home_img}/>
            </a>

            <div className='content'>
                <Header img={header_photo2}/>
                <img src={contatti}/>
                <p className='contatti_paragraph'><span className="style3">ARS NOW SERAGIOTTO</span><br/>
                    Via Croce Rossa, 14<br/>
                    35129 Padova Italy</p>
                <br/><br/>
                <p className='contatti_paragraph'><span className="style3">Tel.</span> +39 049 8070631<br/>
                    <span className="style3">Mauro Casotto</span> +39 335 6126122<br/>
                    <br/>
                    <br/>
                    <a className='facebook_link' href="mailto:info@arsnowseragiotto.it">info@arsnowseragiotto.it</a>&nbsp; &nbsp;&nbsp;</p>
                <Footer/>
            </div>

        </div>
    )
}

export default Contatti;