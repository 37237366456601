import './Filosofia.css'
import home_img from "../../../images/arsnow.jpg";
import Header from "../Header/Header";
import header_photo3 from "../../../images/background_header3.jpg";
import filosofia from "../../../images/it/filosofia.gif";
import Footer from "../Footer/Footer";

function Filosofia(){
    return(
        <div className='filosofia'>
            <div className='news'>
                <a className='img_href' href='/'>
                    <img src={home_img}/>
                </a>

                <div className='content'>
                    <Header img={header_photo3}/>
                    <img src={filosofia}/>
                    <p className="filosofia_paragraph">
                        Il percorso di Ars Now è iniziato
                        nell’autunno 2008, in uno spazio espositivo che aveva
                        la sua sede in via Petrarca, e la prima mostra in cantiere
                        fatta in mezzo a calcinacci ed impalcature. Fu allora che
                        Mauro Casotto da collezionista divenne, seppur da anticonformista,
                        gallerista, passando da una dimensione artistica personale ad una
                        che, inevitabilmente, oltrepassava la sfera privata.<br/><br/></p>
                    <p className="filosofia_paragraph">
                        “Volevo festeggiare in maniera diversa il mio compleanno, invitando gli amici più cari, per
                        renderli partecipi del mio progetto in divenire, cercando anche di raccogliere fondi da
                        destinare ai bimbi di una sperduta missione salesiana in Ethiopya, nel villaggio di Adamitullo. Ancor oggi, sempre nel mese di dicembre, organizziamo una
                        mostra ad hoc, tutta dedicata a chi è meno fortunato di noi e dei nostri figli”
                        <br/><br/>
                    </p>

                    <p className="filosofia_paragraph">
                        Era proprio di questo che Casotto, per indole e carattere, sentiva la necessità: condividere con
                        molti compagni di viaggio questa sua passione per l’arte moderna e contemporanea.
                        <br/><br/>
                    </p>
                    <p className="filosofia_paragraph">
                        Fin dall’inizio Ars Now ha voluto portare nella nostra città artisti importanti infatti la sua
                        inaugurazione ha ospitato due grandi artisti dell’arte contemporanea: Enrico Castellani e
                        Francois Morellet. Nel corso degli anni in cui la galleria è rimasta aperta, il progetto nel suo
                        insieme è sempre stato di tipo artistico-culturale e non commerciale.
                        <br/><br/>
                    </p>


                    <p className="filosofia_paragraph">
                        “Ho cercato di portare nella mia città qualcosa che non credo ci fosse prima. Le gallerie a Padova sono tante, e qualificate, ma tutte o quasi mettono un’anima commerciale all’arte, presentando, quasi sempre, le opere e gli artisti in funzione della loro vendita. Io credo invece che l’arte non debba essere visto come strumento per guadagnare, ma debba essere un mezzo per conoscere, approfondire e crescere. La vera scommessa è proprio questa: avere la possibilità di diffondere i messaggi degli artisti, e di renderne partecipe il maggior numero di persone. L’arte deve essere condivisa, il mio ruolo è semplice ma essenziale: mettere in condizione gli artisti di cui condivido idee e proposte, di esporre nei migliore dei modi, al fine di essere visti, conosciuti”.
                        <br/><br/>
                    </p>
                    <p className="filosofia_paragraph">
                        E’ proprio questo il fine ultimo dell’Associazione Ars Now – l’arte ora – scritta in latino e inglese. La nostra lingua madre del passato affiancata a quella più attuale e globale, perché è soltanto rispettando i classici che si può guardare al futuro.
                        <br/><br/>
                    </p>
                    <p className="filosofia_paragraph">
                        Nel 2013 la galleria di via Petrarca ha chiuso i battenti ma l’attività è continuata. Ars Now rimane una associazione culturale che propone iniziative ed esposizioni, organizzando in media tre mostre all’anno, con gli stessi scopi e la stessa filosofia di quando è nata nel 2008.
                        <br/><br/>
                    </p>

                    <Footer/>

                </div>
            </div>
        </div>
    )
}

export default Filosofia;