import LanguagePage from "./Pages/LanguagePage/LanguagePage";
import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';

import HomePageIt from "./Pages/Italiano/HomePage/HomePage";
import News from "./Pages/Italiano/News/News";
import Filosofia from "./Pages/Italiano/Filosofia/Filosofia";
import Artisti from "./Pages/Italiano/Artisti/Artisti";
import Mostre from "./Pages/Italiano/Mostre/Mostre";
import Contatti from "./Pages/Italiano/Contatti/Contatti";
import Links from "./Pages/Italiano/Links/Links";
import Other from "./Pages/Italiano/Other/Other";

import HomePageItEn from "./Pages/English/HomePage/HomePage";
import NewsEn from "./Pages/English/News/News";
import FilosofiaEn from "./Pages/English/Filosofia/Filosofia";
import ArtistiEn from "./Pages/English/Artisti/Artisti";
import MostreEn from "./Pages/English/Mostre/Mostre";
import ContattiEn from "./Pages/English/Contatti/Contatti";
import LinksEn from "./Pages/English/Links/Links";
import OtherEn from "./Pages/English/Other/Other";


function App() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<LanguagePage />} />

                {/*<Route path='/it/home' element={<HomePageIt/>} />*/}
                {/*<Route path='/it/news' element={<News/>} />*/}
                <Route path='/it/filosofia' element={<Filosofia/>} />
                <Route path='/it/artisti' element={<Artisti/>} />
                <Route path='/it/mostre' element={<Mostre/>} />
                <Route path='/it/contatti' element={<Contatti/>} />
                <Route path='/it/links' element={<Links/>} />
                {/*<Route path='/it/other' element={<Other/>} />*/}

                <Route path='/en/home' element={<HomePageItEn/>} />
                {/*<Route path='/en/news' element={<NewsEn/>} />*/}
                <Route path='/en/filosofia' element={<FilosofiaEn/>} />
                <Route path='/en/artisti' element={<ArtistiEn/>} />
                <Route path='/en/mostre' element={<MostreEn/>} />
                <Route path='/en/contatti' element={<ContattiEn/>} />
                <Route path='/en/links' element={<LinksEn/>} />
                {/*<Route path='/en/other' element={<OtherEn/>} />*/}
            </Routes>
        </Router>
    );
}

export default App;
