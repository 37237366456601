import './Artisti.css'
import home_img from "../../../images/arsnow.jpg";
import HeaderEn from "../../English/Header/Header";
import header_photo2 from "../../../images/background_header2.jpg";
import artists from "../../../images/en/artists.gif";
import Footer from "../Footer/Footer";

function Artisti(){
    return(
        <div className='artisti'>
            <a className='img_href' href='/'>
                <img src={home_img}/>
            </a>

            <div className='content'>
                <HeaderEn img={header_photo2}/>
                <img src={artists}/>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Simmons &amp; Burke</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Ulrich Rückreim</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Alessandro Borgonovo</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Francesco Candeloro</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Sara Campesan</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Enrico Castellani</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Carlo Ciussi</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Dadamaino</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Paolo Ceribelli</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Ben Grasso</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Nadia Costantini</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Ben Grasso</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Riccardo De Marchi</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Giovanni De Sandre</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>François Morellet</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Niccolò Quirico</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Rolando Tessadri</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Paolo De Cuarto</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Günter Umberg</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Mario Nigro</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Pino Pinelli</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Laura Stefani</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Andrew Schoultz</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Bruno Querci</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Rodolfo Aricò</p>
                    <p className='artisti_paragraph'><span className="style3">&gt; </span>Elizabeth Vary</p>


                <Footer/>

            </div>
        </div>
    )
}

export default Artisti;