import './HomePage.css'
import home_img from '../../../images/arsnow.jpg'
import Header from "../Header/Header";
import Footer from '../Footer/Footer'
import header_photo1 from '../../../images/background_header.jpg'
import home from '../../../images/home2.png'
import facebook from '../../../images/facebook.png'


function HomePage(){
    return(
        <div className='homepage'>
            <a className='img_href' href='/'>
                <img src={home_img}/>
            </a>

            <div className='content'>
                <Header img={header_photo1}/>
                <div className='content_filling'>
                    <div className='left_side'>
                        <a className='home_icon' href='/'>
                            <img src={home}/>
                        </a>
                        <p className='paragraph'>
                            A presto con nuove iniziative.. rimanete collegati!<br/>
                            per info contattateci a: <b>info@arsnowseragiotto.it</b>
                        </p>
                        <img className='facebook' src={facebook}/>
                        <p className='paragraph'>
                            Per rimanere sempre aggiornati visitate e seguite la pagina Facebook di ArsNow Magazine:
                        </p>
                        <p className='paragraph'>
                            <a className='facebook_link'
                               href="https://www.facebook.com/arsnowmagazine/?fref=ts ">https://www.facebook.com/arsnowmagazine/?fref=ts </a>
                        </p>
                        <p className='paragraph'>
                            <a className='facebook_link'
                                href="http://www.arsnow-magazine.it/">&gt; visita ArsNowMAGAZINE</a>
                        </p>

                    </div>
                    <div className='right_side'>
                        <p className='paragraph'>
                        IN EVIDENZA
                        </p>
                        <li className='paragraph'>
                            ARSNOW
                        </li>
                    </div>
                </div>
                <Footer/>
            </div>

        </div>
    )
}

export default HomePage;