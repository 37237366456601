import './Header.css'
import img1_en from '../../../images/it/news.gif'
import img2_en from '../../../images/en/philosophy.gif'
import img3_en from '../../../images/en/artists.gif'
import img4_en from '../../../images/en/exhibitions.gif'
import img5_en from '../../../images/en/contact_us.gif'
import img6_en from '../../../images/it/links.gif'
import img7_en from '../../../images/it/other.gif'

function Header(props){
    return(
        <div className='header'>
            <nav>
                {/*<a className='image_span' href='/en/news'>*/}
                {/*    <img src={img1_en}/>*/}
                {/*</a>*/}
                <a className='image_span' href='/en/filosofia'>
                    <img src={img2_en}/>
                </a>
                <a className='image_span' href='/en/artisti'>
                    <img src={img3_en}/>
                </a>
                <a className='image_span' href='/en/mostre'>
                    <img src={img4_en}/>
                </a>
                <a className='image_span' href='/en/contatti'>
                    <img src={img5_en}/>
                </a>
                <a className='image_span' href='/en/links'>
                    <img src={img6_en}/>
                </a>
                {/*<a className='image_span' href='/en/other'>*/}
                {/*    <img src={img7_en}/>*/}
                {/*</a>*/}
            </nav>
            <div className='header_image'>
                <img src={props.img}/>

            </div>

        </div>
    )
}

export default Header;