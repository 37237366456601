import language_background from "../../images/arsnow.jpg";
import "./LanguagePage.css"
function LanguagePage() {
    return (
        <div className="container">
            <div className="container">
                <div className="row">
                    <img className="language_background"
                         src={language_background} width="618"
                         height="713"/>
                    <a id="italiano" href="/it/filosofia">italiano</a>
                    <a id="inglese" href="/en/filosofia">english</a>
                </div>
            </div>
        </div>

    );
}

export default LanguagePage;
