import './HomePage.css'
import home_img from '../../../images/arsnow.jpg'
import HeaderEn from "../../English/Header/Header";
import Footer from '../Footer/Footer'
import header_photo2 from '../../../images/background_header2.jpg'
import home from '../../../images/home2.png'
import facebook from '../../../images/facebook.png'


function HomePage(){
    return(
        <div className='homepage'>
            <a className='img_href' href='/'>
                <img src={home_img}/>
            </a>

            <div className='content'>
                <HeaderEn img={header_photo2}/>
                <div className='content_filling'>
                    <div className='left_side'>
                        <a className='home_icon' href='/'>
                            <img src={home}/>
                        </a>
                        <p className='paragraph'>
                            Stay tuned for the next cultural events!
                        </p>
                        <br/><br/><br/><br/>
                        <p className="paragraph">Info: <b>info@arsnowseragiotto.it</b></p>
                    </div>
                </div>
                <Footer/>
            </div>

        </div>
    )
}

export default HomePage;