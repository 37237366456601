import './Footer.css'

function Footer(){
    return(
        <div className='footer'>
            <span className='line'/>
            <p className='footer_text'>info@arsnowseragiotto.it |  &copy; Copyright Ars Now Seragiotto</p>
        </div>
    )
}

export default Footer;