import './Other.css'
import home_img from "../../../images/arsnow.jpg";
import Header from "../Header/Header";
import header_photo2 from "../../../images/background_header2.jpg";
import other from "../../../images/it/other.gif";
import Footer from "../Footer/Footer";

function Other(){
    return (
        <div className='other'>
            <a className='img_href' href='/'>
                <img src={home_img}/>
            </a>

            <div className='content'>
                <Header img={header_photo2}/>
                <img src={other}/>
                <br/>

                <p className='other_paragraph'><span className="style3">&gt;</span> Sezione in allestimento</p>
            <Footer/>

        </div>
        </div>
    )
}

export default Other;