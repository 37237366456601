import './News.css'
import news from '../../../images/it/news.gif'
import Header from "../Header/Header";
import header_photo2 from "../../../images/background_header2.jpg";
import home_img from '../../../images/arsnow.jpg'
import Footer from "../Footer/Footer";
function News(){
    return(
        <div className='news'>
            <a className='img_href' href='/'>
                <img src={home_img}/>
            </a>

            <div className='content'>
                <Header img={header_photo2}/>
                <img src={news}/>
                <li className="paragraph">A presto con nuove iniziative.. rimanete collegati!</li>
                <Footer/>

            </div>
        </div>
    )
}

export default News;